import {
    $, addFilter, addAction, removeAction, LAYOUTEND,
} from '@situation/setdesign.util';

addFilter('media-carousel/owl-settings', (settings) => ({
    ...settings,
    navText: ['<svg><use xlink:href="#arrow-left"></use></svg>',
        '<svg><use xlink:href="#arrow-right"></use></svg>'],
}));

function showCarousel() {
    $('.media-carousel').addClass('media-carousel--show');
    setTimeout(() => {
        removeAction(LAYOUTEND, showCarousel);
    }, 0);
}

addAction(LAYOUTEND, showCarousel);
