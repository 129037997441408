import {
    $, addAction, doAction, INIT, GTM_INTERACTION,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.region-selector').each((index, el) => {
        const $selector = $(el);
        $selector.on('change', () => {
            doAction(GTM_INTERACTION, {
                type: 'region-select',
                label: $selector.find(`option[value='${$selector.val()}']`).text(),
                destination: $selector.val(),
                direction: 'outbound',
            });
            window.open($selector.val(), '_blank');
        });
    });
});
