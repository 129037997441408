import {
    $, addAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const numberOfStars = 100;
    const $container = $('.home-hero').not('.vc-hidden').first();
    if (!$container.length || SetDesign.viewport.width() < 480) {
        return;
    }
    const $bg = $container.find('.content-background-image');
    let $stars = $();

    function round(val) {
        return Math.round(val * 10) / 10;
    }

    function renderStars() {
        for (let i = 0; i < numberOfStars; i += 1) {
            const top = round(Math.random() * 100);
            const left = round(Math.random() * 100);
            const duration = round(5 + Math.random() * 10);
            const $star = $('<span class="home-hero__star">').css({
                top: `${top}%`,
                left: `${left}%`,
                animationDuration: `${duration}s`,
            });
            $stars = $stars.add($star);
        }

        $bg.append($stars);
    }

    renderStars();
    $stars.each((index, el) => {
        const $star = $(el);
        setTimeout(() => {
            $star.addClass('home-hero__star--show');
        }, (index * 250) + (Math.random() * 2000));
    });
});
