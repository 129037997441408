import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.honoree--inline').on('click', (e) => {
        const $container = $(e.currentTarget);
        if (!$(e.target).hasClass('honoree__read-more')) {
            $container.find('.honoree__read-more').trigger('click');
        }
    });
});
