import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.newsletter-teaser').each((index, el) => {
        const $form = $(el);
        const $sourceInput = $form.find('.newsletter-teaser__email');
        const $target = $($form.data('targetForm'));
        const $targetField = $target.find('input[type="email"]');
        $form.on('submit', (e) => {
            e.preventDefault();
            $targetField.val($sourceInput.val());
            doAction('showModal', $target);
        });
    });
});
